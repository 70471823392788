/*******************************************************************
Main
******************************************************************/

body {
    // background-color is set in App.js
    display: flex;
    justify-content: center;
    height: 100vh;
    font-family: 'Graphik';
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.22px;

    .content {
        min-height: calc(100vh - 200px);
        padding: 70px 20px 0;
    }

    #root {
        width: 100%;
    }
}

a {
    color: $black;
}

input:-internal-autofill-selected {
    background: transparent !important;
}


/*******************************************************************
Header
******************************************************************/

#header {
    padding: 25px 20px 0;
    height: 46px;
    position: fixed;
    width: calc(100vw - 40px);
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    #header-logo {
        background-image: url('/static/images/Greenstreet Logo HEADER.svg');
        width: 130px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        left: 25px;
    }

    #nav-button {
        width: 30px;
        height: 21px;
        position: relative;
        display: inline-block;
        -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
             -o-transition: .5s ease-in-out;
                transition: .5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 50%;
            background: $black;
            opacity: 1;
            -webkit-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
                 -o-transition: .25s ease-in-out;
                    transition: .25s ease-in-out;

            &:nth-child(even) {
                left: 50%;
                border-radius: 0;
            }

            &:nth-child(odd) {
                left: 0px;
                border-radius: 0;
            }

            &:nth-child(1), &:nth-child(2) {
                top: 0px;
            }

            &:nth-child(3), &:nth-child(4) {
                top: 9px;
            }

            &:nth-child(5), &:nth-child(6) {
                top: 18px;
            }
        }

        &.open {
            span {
                &:nth-child(1), &:nth-child(6) {
                    -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                }

                &:nth-child(2), &:nth-child(5) {
                    -webkit-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                }

                &:nth-child(1) {
                    top: 2px;
                }

                &:nth-child(2) {
                    left: calc(50% - 5px);
                    top: 2px;
                }

                &:nth-child(3) {
                    left: -50%;
                    opacity: 0;
                }

                &:nth-child(4) {
                    left: 100%;
                    opacity: 0;
                }

                &:nth-child(5) {
                    top: 12px;
                }

                &:nth-child(6) {
                    left: calc(50% - 5px);
                    top: 12px;
                }
            }
        }
    }

    #s-m-icons {
        display: flex;
        float: right;
        justify-content: center;
        align-items: center;
    }
}


/*******************************************************************
Footer
******************************************************************/

#footer {
    height: 132px;
    width: 100%;
    border-top: 2px solid $black;
    display: flex;
    justify-content: space-between;
    align-items: center;

    #footer-info {
        display: flex;

        #footer-logo {
            background-image: url('/static/images/Greenstreet Mark FOOTER.svg');
            width: 102px;
            height: 102px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 30px;

        }

        #footer-links {
            display: flex;
            flex-direction: column;
            margin-left: 40px;

            a {
                text-decoration: none;
                color: $black;
                margin-bottom: 7px;
            }

            .newsletter-link:hover {
                cursor: pointer;
            }
        }
    }

    #footer-copyright {
        margin-right: -220px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 30px);

        #midway-logo {
            background-image: url('/static/images/Midway Logo.svg');
            height: 14px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    #footer-contact {
        display: flex;
        flex-direction: column;
        height: calc(100% - 30px);
        justify-content: space-between;
        margin-right: 30px;

        #footer-phone {
            color: $black;
            text-decoration: none;
        }

        #footer-email {
            text-decoration: underline;
        }
    }

    .cp-mobile {
        display: none;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;

        #footer-info {
            flex-direction: row-reverse;
            justify-content: space-between;
            width: calc(100% - 30px);
            margin: 10px 15px 0;
            min-height: 120px;

            #footer-links {
                margin-left: 0;
            }
        }

        #footer-copyright {
            width: calc(100% - 30px);
            margin: 0 15px;
            min-height: 15px;
            align-items: flex-end;

            a {
                width: 100px;
                margin-right: 5px;
            }
        }

        #footer-contact {
            width: calc(100% - 30px);
            margin: 0 15px;
            min-height: 100px;
            margin-bottom: 30px;
        }

        .cp-mobile {
            width: calc(100% - 30px);
            margin: 0 15px;
            display: flex;
            margin-bottom: 20px;
            min-height: 18px;
        }

        .cp-desktop {
            display: none;
        }
    }
}


/*******************************************************************
Nav
******************************************************************/

#menu {
    background: $magenta;
    width: 100%;
    overflow-y: hidden;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 30px;
    font-size: 60px;
    transform: translateY(0);
    transition: transform 3s;

    div {
        padding-left: 30px;
    }

    a {
        text-decoration: none;
        color: $black;
        transition: 0.3s color ease-in-out;

        &:hover {
            color: $white;
            transition: 0.3s color ease-in-out;

            @media screen and (max-width: 800px) {
                color: $black;
            }
        }
    }

    &.hide {
        transform: translateY(-100%);
        transition: transform 3s;
    }

    #things-to-do-menu-header {
        cursor: pointer;
        transition: 0.3s color ease-in-out;

        &:hover {
            color: $white;
            transition: 0.3s color ease-in-out;

            @media screen and (max-width: 800px) {
                color: $black;
            }
        }
    }

    #things-to-do-menu {
        margin-left: 40px;
        display: flex;
        flex-direction: column;

        &.hide-menu {
            max-height: 0;
            overflow: hidden;
            -webkit-transition: max-height 1s ease;
        }

        &.active {
            max-height: 490px;
            overflow: hidden;
        	-webkit-transition: max-height 1s ease;
        }
    }

    @media screen and (max-width: 800px) {
        font-size: 40px;

        #things-to-do-menu {
            &.active {
                max-height: 320px;
            }
        }
    }
}


/*******************************************************************
 Icons
 ******************************************************************/

.left-arrow, .right-arrow, .down-arrow, .up-arrow {
    background-image: url('/static/images/Arrow Button LG.svg');
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    height: calc(67px * 0.7);
    width: calc(67px * 0.7);
}

.green-arrow {
    background-image: url('/static/images/Arrow Button LG Green.svg');
}

.left-arrow {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}

.right-arrow {
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.up-arrow {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.scroll-down {
    position: relative;
    top: -60px;
    left: calc(50% - 30px);
}

.wedge {
    position: relative;
    background-image: url('/static/images/Wedge.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.wedge-prev {
    left: -20px;
}

.wedge-next {
    right: -20px;
}

.twitter {
    width: 19px;
    height: 16px;
    background-image: url('/static/images/Twitter Logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &.green-logo {
        background-image: url('/static/images/Twitter Logo Green.svg');
    }
}

.facebook {
    width: 11px;
    height: 17px;
    background-image: url('/static/images/Facebook Logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &.green-logo {
        background-image: url('/static/images/Facebook Logo Green.svg');
    }
}

.instagram {
    height: 18px;
    width: 18px;
    background-image: url('/static/images/Instagram Logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &.green-logo {
        background-image: url('/static/images/Instagram Logo Green.svg');
    }
}

.email {
    height: 18px;
    width: 18px;
    background-image: url('/static/images/mail.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &.green-logo {
        background-image: url('/static/images/mail-green.svg');
    }
}

#s-m-icons {
    display: flex;
    float: unset;
    justify-content: center;
    align-items: center;

    div {
        height: 16px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 5px;
    }
}

.calendar-view-icon {
    background-image: url(/static/images/calendar_view.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.list-view-icon {
    background-image: url(/static/images/list_view.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.filter-date-icon {
    background-image: url(/static/images/filter_dates.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}


/*******************************************************************
 Map List and Icons
 ******************************************************************/

.location-list {
    width: 34%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .locations {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0 -10px -20px;
    }

    .location {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 10px 25px;

        .level-1, .level-2, .level-3, .level-1-2, .level-2-3, .all-levels {
            height: 30px;
            width: 30px;
            min-width: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
        }

        .level-1 {
            background: $magenta;
        }

        .level-2 {
            background: $yellow;
        }

        .level-3 {
            background: $cyan;
        }

        .level-1-2 {
            background: -webkit-linear-gradient(-45deg, $magenta 0%,$magenta 50%,$yellow 50%,$yellow 100%);
            background: -webkit-linear-gradient(315deg, $magenta 0%,$magenta 50%,$yellow 50%,$yellow 100%);
            background: -o-linear-gradient(315deg, $magenta 0%,$magenta 50%,$yellow 50%,$yellow 100%);
            background: linear-gradient(135deg, $magenta 0%,$magenta 50%,$yellow 50%,$yellow 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$magenta', endColorstr='$yellow', GradientType=1);
        }

        .level-2-3 {
            background: -webkit-linear-gradient(-45deg, $yellow 0%,$yellow 50%,$cyan 50%,$cyan 100%);
            background: -webkit-linear-gradient(315deg, $yellow 0%,$yellow 50%,$cyan 50%,$cyan 100%);
            background: -o-linear-gradient(315deg, $yellow 0%,$yellow 50%,$cyan 50%,$cyan 100%);
            background: linear-gradient(135deg, $yellow 0%,$yellow 50%,$cyan 50%,$cyan 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$yellow', endColorstr='$cyan', GradientType=1);
        }

        .all-levels {
            background: $magenta; /* Old browsers */
            background: -moz-linear-gradient(-45deg, $magenta 0%, $magenta 33%, $yellow 33%, $yellow 66%, $cyan 66%, $cyan 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg, $magenta 0%,$magenta 33%,$yellow 33%,$yellow 66%,$cyan 66%,$cyan 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, $magenta 0%,$magenta 33%,$yellow 33%,$yellow 66%,$cyan 66%,$cyan 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$magenta', endColorstr='$cyan',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }

        .location-list-info {
            margin-left: 20px;

            .location-name {
                font-weight: 500;
                font-size: 14px;
            }

            .location-category {
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    @media screen and (max-width: 800px) {
        &:not(.white) {
            padding-left: 0;
            padding-right: 0;

            .location:last-child {
                padding-bottom: 0;
            }
        }
    }
}


/*******************************************************************
 Newsletter Form
 ******************************************************************/

input[type=text], textarea, select, button {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: none;
            box-sizing: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;

    &:focus {
        outline: 0;
    }
}

input[type=checkbox] {
    display: none;
}

.checkmark {
    border: 2px solid $black;
    height: 19px;
    width: 20px;
    background-color: transparent;
    cursor: pointer;
}

input:checked ~ .checkmark:after {
    display: block;
}

.checkmark:after {
    content: "";
    position: relative;
    display: none;
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid $black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input[type=text] {
    border-bottom: 2px solid $black;
}

textarea {
    border: 2px solid $black;
    resize: none;
}

select {
    border-bottom: 2px solid $black;
    background: transparent;
    border-radius: 0;
    background-image: url('/static/images/select_arrow.svg');
    background-position: right;
    background-size: 20px;
    background-repeat: no-repeat;
}

.newsletter-form {
    width: 70%;
    @media screen and (max-width: 800px) {
        width: 100%;
    }

    .error {
        input {
            border-bottom-color: $red !important;
        }
    }

    .disabled-submit {
        opacity: 0.3;
        cursor: default !important;
        background: transparent;

        .right-arrow {
            cursor: default !important;
        }
    }

    .info {
        margin-bottom: 40px;
    }

    label {
        display: flex;
        flex-direction: column;

        span {
            margin-top: 5px;
            margin-bottom: 20px;
            font-size: 15px;
        }

        textarea {
            resize: none;
            height: 75px;
        }
    }

    .form-message {
        color: $green;
        color: #009735;
    }

    .form-error {
        color: $red;
    }

    .form-location {
        flex-direction: row;
        justify-content: space-between;

        label {
            width: 48%;

            input {
                width: 100%;
            }
        }
    }

    .checkbox {
        flex-direction: row;
        align-items: center;

        span {
            margin-right: 8px;
        }
    }

    button {
        cursor: pointer;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;

        .right-arrow {
            margin-left: 10px;
            height: 40px;
            width: 40px;
        }

        .green-arrow {
            margin-left: 10px;
            height: 35px;
            width: 35px;
        }
    }
}


/*******************************************************************
Modal
******************************************************************/

.ReactModal__Content--after-open {
    position: unset !important;
    border: 15px solid $green !important;
    background: $white !important;
    overflow: visible !important;
    overflow-x: scroll;
    border-radius: 0 !important;
    outline: none !important;
    padding: 20px !important;
    width: 62%;
    width: calc(1142px * 0.7);
    min-width: 500px;
    height: 80vh;
}

@media screen and (max-width: 800px) {
    .ReactModal__Content--after-open {
        max-width: calc(100vw - 120px);
        min-width: calc(100vw - 120px);
        height: 70vh;
    }
}

.ReactModal__Overlay--after-open {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.close {
    cursor: pointer;
    background-image: url('/static/images/close-button.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    position: relative;
    top: -56px;
    left: calc(100% + 15px);
}


/*******************************************************************
Video Player
******************************************************************/

.react-player__preview {
    height: 600px !important;
    background-image: url(/media/filer_public_thumbnails/filer_public/49/00/49009e63-a1b0-4869-b2db-7832bfd3c045/mainstreetsm_FJNsqn5.jpg__1420x1040_q85_subsampling-2_upscale.jpg) !important;
}

.react-player__play-icon {
    border: 0 !important;
    background-image: url(/static/images/play_video.svg) !important;
    height: 125px;
    width: 125px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.react-player__shadow {
    background: none !important;
    height: 125px !important;
    width: 125px !important;
}

.player {
    height: 600px !important;
}

.video-modal {
    height: 100vh;
    width: 100vw;
    background: $black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;

    iframe {
        height: calc(100vh - 40px) !important;
    }

    .close-container {
        height: 40px;
        width: 40px;
        background: rgba($black, 0.5);
    }

    .close-video {
        color: white;
        position: absolute;
        right: 16px;
        font-size: 35px;
    }
}

.video-thumbnail {
    height: 600px;
    width: 100%;
    background-image: url(/media/filer_public_thumbnails/filer_public/49/00/49009e63-a1b0-4869-b2db-7832bfd3c045/mainstreetsm_FJNsqn5.jpg__1420x1040_q85_subsampling-2_upscale.jpg) !important;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    margin-bottom: 30px;

    .video-play-button {
        height: 100%;
        background-image: url(/static/images/play_video.svg);
        background-position: center;
        background-size: 200px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}


/*******************************************************************
Blocks
******************************************************************/

.blocks {
    display: flex;
    justify-content: center;
    font-size: 22px;
    margin-bottom: 40px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }

    .block {
        width: 49%;
        padding: 0 30px 0 20px;

        @media screen and (max-width: 800px) {
            padding-left: 0;
            padding-right: 0;
        }

        &.full-block {
            width: 100%;
            padding: 0;
        }
    }

    .full-block {
        .content-block {
            width: 100%;
        }
    }

    .left-block {
        border-right: 1px solid $black;
        padding-left: 30px;

        @media screen and (max-width: 800px) {
            border-right: 0;
            padding: 0;
            width: 100%;
        }
    }

    .right-block {
        @media screen and (max-width: 800px) {
            border-right: 0;
            padding-left: 0;
            width: 100%;
        }
    }

    .about-title {
        margin-top: 0px;
    }

    p {
        margin: 20px 0 0;
    }

    .content-block {
        margin-bottom: 40px;
    }

    .content-block {
        .expanded-content-button {
            margin-top: 15px;
            cursor: pointer;

            .expand {
                display: flex;
                align-items: center;
                font-size: 15px;
                overflow: hidden;

                .right-arrow, .up-arrow {
                    margin-left: 15px;
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }

    .full-block {
        margin-right: 30px;

        .content-block {
            border-top: 2px solid $black;
            padding-top: 25px;
            padding-left: 30px;

            &.first-block {
                border-top: 0;
                padding-top: 0;
            }
        }
    }

    @media screen and (max-width: 800px) {
        font-size: 18px;

        .main-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .cke-block {
                width: 280px;
            }
        }
    }
}

.intro {
    margin-left: 30px;
    margin-bottom: 50px;
}

.outro {
    border-top: 2px solid $black;
    padding-left: 30px;
    margin-top: 20px;
    padding-top: 30px;
    margin-bottom: 80px;
}

/*******************************************************************
Fonts and Global Style
******************************************************************/

.green {
    background: $green;
}

.magenta {
    background: $magenta;
}

.button, .cke-button {
    display: inline-block;
    font-family: 'Graphik';
    padding: 10px 35px;
    border: 2px solid $black;
    border-radius: 20px;
    background: $green;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1.5px;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    &.disabled {
        border: 1px solid rgba($black, .4);
        color: rgba($black, .4);
        cursor: default;
    }

    a {
        text-decoration: none;
    }
}

.title {
    font-family: 'Produkt';
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 0.55px;

    @media screen and (max-width: 800px) {
        font-size: 30px;
    }
}

.subtitle, .info {
    font-family: 'Graphik';
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 15px;
    text-transform: uppercase;
}

.info {
    letter-spacing: 1.5px;
    font-size: 13px;
}

.map-legend-title {
    font-size: 10px;
    font-weight: 400;
    font-family: 'Graphik';
}

.map-legend {
    font-size: 8px;
    font-weight: 700;
    font-family: 'Graphik';
}

.alt-link {
    color: #3F7D4A !important;
    text-decoration: none;
    transition: 0.3s color ease-in-out;

    &:hover {
        color: $white !important;
        transition: 0.3s color ease-in-out;
    }
}

.no-decoration {
    text-decoration: none;
}

.hide {
    display: none !important;
}

/*******************************************************************
CKEditor
******************************************************************/

.cke-block {
    display: inline-table;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;

    .cke-sub-title {
        width: 300px;
        display: inline-block;
    }

    @media screen and (max-width: 800px) {
        width: 280px;
    }
}

.cke-wedge:before {
    content: '';
    display: inline-block;
    background-image: url('/static/images/Wedge black.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.cke-sub-title {
    display: inline-block;
    font-family: 'Graphik';
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 12px;
    text-transform: uppercase;
}

.cke-title, .cke-directions {
    display: inline-block;
    font-family: 'Produkt';
    font-size: 39px;
    font-weight: 200;
    letter-spacing: 0.55px;
}

@media screen and (max-width: 800px) {
    .cke-title {
        font-size: 30px;
    }
}

.cke-directions {
    display: flex;
    align-items: center;

    a {
        text-decoration: none;
        display: flex;
        align-items: center;

        &:after {
            content: '';
            display: inline-block;
            background-image: url('/static/images/Arrow Button SM.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
    }
}

.cke-button {
    padding: 10px 40px;
}

.cke-note {
    font-size: 12px;
    font-family: 'Graphik';
    font-weight: 300;
    text-transform: none;
    letter-spacing: 0.25px;
}

/*******************************************************************
Carousel
******************************************************************/

.image-carousel {
    .slider-frame {
        min-height: 750px;
    }

    .image-item {
        p {
            margin: 0;
        }

        div {
            display: block;
            width: 100%;
            height: 750px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .legend {
            max-width: 500px;
            position: relative;
            top: 20px;
            left: 20px;
            font-size: 60px;
            font-family: 'Produkt';
            font-weight: 300;
            color: $white;

            &.place {
                top: -10px;
                height: 100%;
                display: flex;
                -webkit-box-align: end;
                    -ms-flex-align: end;
                        align-items: flex-end;
                font-size: 13px;
                font-family: 'Graphik';
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 2.67px;
            }
        }

        .tinted {
            background: rgba(0, 0, 0, .2);
        }
    }

    @media screen and (max-width: 800px) {
        .image-item div {
            height: 550px;

            .legend {
                font-size: 40px;
                max-width: calc(100% - 30px);
            }
        }

        .slider-frame {
            min-height: 550px;
        }
    }
}


/*******************************************************************
Calendar/News Head
******************************************************************/

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .title {
        margin-left: 30px;

        a {
            color: $black;
            text-decoration: none;
        }
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;

        @media screen and (orientation: landscape) {
            flex-direction: row;
            align-items: center;
        }

        .title {
            margin-left: 0;
            margin-bottom: 15px;
        }
    }
}

/*******************************************************************
Card
******************************************************************/

.card {
    cursor: pointer;
    height: calc(742px * 0.7);
    width: 25%;
    min-width: calc(382px * 0.7);
    max-width: calc(25% - 50px);
    background: $white;
    margin: 10px;
    padding: 15px;

    @media screen and (min-width: 2800px) {
        width: 14.28%;
        max-width: calc(14.28% - 50px);

        &.first-card {
            width: calc(100% - 60px) !important;
            max-width: 100% !important;
        }
    }

    @media screen and (max-width: 2800px) {
        width: 16.67%;
        max-width: calc(16.67% - 51px);

        &.first-card {
            width: calc(100% - 60px) !important;
            max-width: 100% !important;
        }
    }

    @media screen and (max-width: 2250px) {
        width: 20%;
        max-width: calc(20% - 50px);

        &.first-card {
            width: calc(100% - 60px) !important;
            max-width: 100% !important;
        }
    }

    @media screen and (max-width: 1610px) {
        width: 25%;
        max-width: calc(25% - 50px);

        &.first-card {
            width: calc(100% - 60px) !important;
            max-width: 100% !important;
        }
    }

    @media screen and (max-width: 1290px) {
        width: 33%;
        max-width: calc(33% - 50px);

        &.first-card {
            width: calc(100% - 60px) !important;
            max-width: 100% !important;
        }
    }

    @media screen and (max-width: 973px) {
        width: 50%;
        max-width: calc(50% - 50px);

        &.first-card {
            width: calc(100% - 50px) !important;
            max-width: 100% !important;

            .card-info {
                width: calc(50% - 25px) !important;
            }

            .card-image {
                width: calc(50% - 10px) !important;
            }
        }
    }

    @media screen and (max-width: 655px) {
        width: 100%;
        max-width: 100%;

        &.first-card {
            width: 100% !important;
            max-width: 100% !important;

            .card-info {
                width: 100% !important;
            }

            .card-image {
                display: none !important;
            }
        }
    }

    background: $white;
    margin: 10px;
    padding: 20px 15px 15px;
}

/*******************************************************************
Page 404
******************************************************************/

$color_404_magenta: #ff86f1;
$color_404_cyan: #00ffdd;
$color_404_blue: #618ff3;
$color_404_yellow: #fff900;

body.page-404 {
    background-color: #000 !important;

    #header {
        background-color: transparent !important;

        #nav-button span {
            background-color: #fff !important;
        }

        #header-logo {
            background-image: url('/static/images/Greenstreet Logo HEADER white.svg');
        }

        .twitter {
            background-image: url('/static/images/Twitter Logo White.svg');
        }

        .facebook {
            background-image: url('/static/images/Facebook Logo White.svg');
        }

        .instagram {
            background-image: url('/static/images/Instagram Logo White.svg');
        }
    }

    #footer {
        border-top: 0 !important;
        padding-top: 20px;
    }

    #not-found {
        background-color: #000;
        padding-left: 0;
        padding-right: 0;
        min-height: auto;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 91%;
            background: url('/static/images/404-strips-top.svg') no-repeat 50% -5%/contain;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            transform: scaleY(0);
            transform-origin: 50% 0%;
            transition: transform .1s;
        }

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 71%;
            background: url('/static/images/404-strips-bottom.svg') no-repeat 50% 105%/contain;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 1;
            transform: scaleY(0);
            transform-origin: 50% 100%;
            transition: transform .1s;
        }

        .container {
            width: 100%;
            height: 0;
            padding-bottom: 90%;
            position: relative;
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            transform: translate3d(0,0,0);
            opacity: 0;
            transition: opacity .1s .05s;

            .top {
                width: 100%;
                max-width: 82vw;
                margin: 0 auto;
                padding-bottom: 30px;
                border-bottom: 2px solid #000;
                font-family: 'Produkt';
                font-size: 9vw;
                font-weight: 200;
                text-align: left;
            }

            .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                max-width: 80vw;
                margin: 0 auto;
                padding-top: 20px;
                font-family: 'Graphik';
                font-weight: 600;
                text-transform: uppercase;

                div:nth-child(1) {
                    padding-right: 20px;
                    margin-top: -12px;
                    font-size: 56px;
                    letter-spacing: 2px;
                }

                div:nth-child(2) {
                    font-size: 13px;
                    line-height: 1.8;
                    letter-spacing: 1.5px;
                    cursor: pointer;

                    &:after {
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        width: 40px;
                        height: 40px;
                        background: url('/static/images/Arrow Button SM Black.svg') no-repeat 50% 50%/contain;
                    }
                }
            }
            @media screen and (max-width: 800px) {
                .top {
                    font-size: 11vw;
                }

                .bottom {
                    div:nth-child(1) {
                        margin-top: -5px;
                        font-size: 30px;
                        letter-spacing: 1px;
                    }

                    div:nth-child(2) {
                        font-size: 10px;
                        letter-spacing: 1px;

                        &:after {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }

        svg.grid {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .bg {
                fill: transparent;
            }

            g {
                path {
                    fill: #fff;
                }
            }
        }
    }

    &.page-404-state-1 {
        #not-found {
            &:before {
                transform: scaleY(1);
            }
        }
    }

    &.page-404-state-2 {
        background-color: rgb(70,255,135) !important;
        transition: background-color .1s;

        #header {
            background-color: transparent !important;

            #nav-button span {
                background-color: #000 !important;
            }

            #header-logo {
                background-image: url('/static/images/Greenstreet Logo HEADER.svg');
            }

            .twitter {
                background-image: url('/static/images/Twitter Logo.svg');
            }

            .facebook {
                background-image: url('/static/images/Facebook Logo.svg');
            }

            .instagram {
                background-image: url('/static/images/Instagram Logo.svg');
            }
        }

        #not-found {
            background-color: rgb(70,255,135);
            transition: background-color .1s;

            &:before {
                transform: scaleY(0);
            }

            &:after {
                transform: scaleY(1);
            }

            svg.grid {
                g path {
                    transition: fill .1s;
                }

                g.row-1 {
                    path:nth-child(odd) {
                        fill: $color_404_magenta;
                    }

                    path:nth-child(even) {
                        fill: $color_404_cyan;
                    }
                }

                g.row-2 {
                    path:nth-child(odd) {
                        fill: $color_404_blue;
                    }

                    path:nth-child(even) {
                        fill: $color_404_cyan;
                    }
                }

                g.row-3 {
                    path {
                        fill: $color_404_yellow;
                    }
                }

                g.row-4 {
                    path:nth-child(odd) {
                        fill: $color_404_cyan;
                    }

                    path:nth-child(even) {
                        fill: $color_404_magenta;
                    }
                }

                g.row-5 {
                    path {
                        fill: $color_404_blue;
                    }
                }

                g.row-6 {
                    path:nth-child(odd) {
                        fill: $color_404_yellow;
                    }

                    path:nth-child(even) {
                        fill: $color_404_cyan;
                    }
                }

                g.row-7 {
                    path {
                        fill: $color_404_magenta;
                    }
                }

                g.row-8 {
                    path:nth-child(odd) {
                        fill: $color_404_blue;
                    }

                    path:nth-child(even) {
                        fill: $color_404_yellow;
                    }
                }

                g.row-9 {
                    path {
                        fill: $color_404_cyan;
                    }
                }

                g.row-10 {
                    path {
                        fill: $color_404_yellow;
                    }
                }
            }
        }
    }

    &.page-404-state-3 {
        #not-found {
            &:before {
                transform: scaleY(0);
            }

            &:after {
                transform: scaleY(0);
            }

            .text {
                opacity: 1;
            }
        }
    }
}


/*******************************************************************
Modal
******************************************************************/

.modal-container {
    height: 100vh;
    width: 100vw;

    .modal-background {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10000;
        opacity: 1;
        visibility: visible;
        background: rgba(0, 0, 0, 0.65);
        transition: all 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal {
        visibility: visible;
        backface-visibility: visible;
        transform: translateX(-50%) translateY(-50%);
        transition: all 0.3s;
        position: fixed;
        top: 50%;
        left: 50%;
        width: 50%;
        max-width: 1000px;
        min-width: 320px;
        height: auto;
        z-index: 1000000;
        background: $violet;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 900px) {
            width: 100vw;
            max-width: 100vw;
            max-height: 100vh;
        }
    }

    .modal-content {
        padding: 60px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1000000;
        text-align: center;
    }

    .modal-logo {
        background-image: url('/static/images/Greenstreet Logo HEADER white.svg');
        background-repeat: no-repeat;
        width: 123px;
        height: 20px;
    }

    .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        height: 50px;
        width: 50px;
        z-index: 10000000000;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 30px;
            height: 2px;
            background-color: $white;
            top: 25px;
            right: 10px;
        }

        &:before{
            transform: rotate(45deg);
        }

        &:after{
            transform: rotate(-45deg);
        }
    }

    &.hide {
        .modal-background {
            opacity: 0;
            visibility: hidden;
        }

        .modal {
            visibility: hidden;
            backface-visibility: hidden;
            transform: translateX(-50%) translateY(-70%);
        }
    }
}
