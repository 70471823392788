#place {
    .image-carousel {
        margin-bottom: 20px;
    }

    .places-info-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-bottom: 20px;
        margin-bottom: 50px;

        &:after {
            content: '';
            border-bottom: 2px solid $black;
            width: 100%;
        }

        .place {
            width: 49%;
            margin: 10px 10px 70px;

            .place-image {
                height: 367px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .place-info {
                margin-left: 20px;

                .place-description {
                    max-width: 450px;
                }

                .place-website {
                    margin-top: 20px;

                    a {
                        color: $black;
                    }
                }

                .place-title {
                    margin-top: 40px;
                }

                .place-social-icons {
                    display: flex;
                    justify-content: space-between;
                    width: 70px;
                    margin-top: 20px;
                }
            }
        }
    }

    .toggle-map-button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    #place-location-list {
        margin-top: 30px;
        margin-bottom: 60px;
        display: flex;
        justify-content: space-between;

        .map {
            width: 65%;
        }

        .location-maps {
            width: 66%;

            .map {
                width: 100%;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                height: 0;
                padding: 0;
            }

            .map-icon {
                position: relative;
                width: 100%;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                height: 0;
                padding: 0;
            }
        }
    }

    .carousel-control {
        &.left-arrow {
            margin-left: 10px;
        }

        &.right-arrow {
            margin-right: 10px;
        }
    }

    .head {
        margin-bottom: 12px;

        .title {
            margin-left: 25px;
            text-transform: capitalize;

            span {
                &.page {
                    color: $white;
                    display: inline-flex;
                    align-items: center;
                }

                &.category {
                    cursor: pointer;
                    color: $black;
                    transition: 0.3s color ease-in-out;

                    &:hover {
                        color: $white;
                        transition: 0.3s color ease-in-out;
                    }
                }

                .menu-divider {
                    margin-top: 9px;
                    margin-left: 5px;
                    margin-right: 10px;
                    height: 18px;
                    width: 18px;
                    background: url(/static/images/menu_divider.svg);
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    @media screen and (min-width: 2300px) {
        .places-info-list {
            .place {
                width: 25%;
                max-width: calc(25% - 20px);
            }
        }
    }

    @media screen and (max-width: 2300px) {
        .places-info-list {
            .place {
                width: 33%;
                max-width: calc(33% - 20px);
            }
        }
    }

    @media screen and (max-width: 1500px) {
        .places-info-list {
            .place {
                width: 50%;
                max-width: calc(50% - 20px);
            }
        }
    }

    @media screen and (max-width: 800px) {
        .places-info-list {
            .place {
                width: 100%;
                max-width: 100%;

                .place-info {
                    margin-left: 0;
                }
            }
        }

        #place-location-list {
            flex-direction: column;
            margin-bottom: 30px;

            .location-list {
                width: 100%;
            }

            .map {
                width: 100%;
            }

            .location-maps {
                width: 100%;
            }
        }

        .head {
            .title {
                margin-left: 0;
            }
        }

        .image-carousel {
            min-height: 350px;

            .image-item div, .slider-frame, .slider-slide {
                min-height: 350px;
                height: 350px;
            }
        }

        @media screen and (orientation: landscape) {
            .image-carousel {
                min-height: 250px;

                .image-item div, .slider-frame, .slider-slide {
                    min-height: 250px;
                    height: 250px;
                }
            }

            .location-list {
                flex-direction: row;
                flex-wrap: wrap;

                .location {
                    width: 50%;
                }
            }
        }
    }
}
