#calendar {
    .head {
        .options {
            display: flex;
            align-items: flex-end;

            .filter-dates, .change-view {
                margin-left: 10px;
            }

            .change-view {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
            }

            .filter-dates {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
            }

            .clear-search {
                margin-left: 10px;
                font-size: 11px;
                cursor: pointer
            }
        }
    }

    .card-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0 -10px;
    }

    .calendar-buttons {
        display: flex;
        justify-content: center;

        .load-more {
            margin-top: 25px;
            margin-bottom: 32px;
        }
    }
}


/*******************************************************************
Event Cards
 ******************************************************************/

.event-card {
    &.empty {
        background: rgba(255, 255, 255, 0.6);
    }

    .date {
        border-bottom: 1px solid $black;

        .day {
            font-size: 89px;
        }
    }

    .first-event {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - calc(230px * 0.7));

        .title {
            padding-top: 15px;
        }

        .hours {
            margin-top: 5px;
        }

        .location {
            text-transform: uppercase;
        }

        .extra-events {
            margin-top: 30px;
            margin-bottom: -47px;
        }
    }

    .see-all {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $black;
        padding-top: 12px;
        margin-top: 8px;

        .event-arrow {
            height: 30px;
            width: 30px;
        }
    }
}


/*******************************************************************
Event List
 ******************************************************************/

.event-list {
    border-top: 1px solid $black;
    margin-top: 25px;
    padding-top: 15px;

    .header {
        width: calc(100% - 60px);
        padding-left: 30px;
        display: flex;
        justify-content: space-between;

        div {
            width: 15%;
            text-align: left;

            &:nth-of-type(1) {
                width: 55%;
                margin-right: 20px;
            }
        }
    }

    .item {
        margin: 25px 0;
        display: flex;
        justify-content: space-between;

        div {
            width: 15%;
            text-align: left;

            &.title {
                width: 55%;
                margin-right: 20px;
            }
        }

        .date-time {
            .info {
                text-transform: capitalize;
            }

            div {
                width: 100%;
            }
        }

        .more-info {
            display: flex;

            .right-arrow {
                height: 30px;
                width: 30px;
                padding-top: 0;
                margin-left: 15px;
                margin-top: -7px;
            }
        }
    }

    .daily-items {
        margin-left: 30px;
        width: calc(100% - 60px);
        margin-top: 25px;
        border-top: 1px solid $black;
        padding-top: 13px;

        &.first {
            border-top: 0;
            padding-top: 0;
        }
    }

    @media screen and (max-width: 800px) {
        .item {
            flex-wrap: wrap;
            align-items: flex-start;

            div {
                width: 100%;

                &.title {
                    width: 100%;
                    margin-right: 0;
                }

                &.more-info, &.date-time {
                    margin-top: 10px;
                }
            }
        }

        .daily-items {
            margin-left: 0;
            width: 100%;
        }
    }
}


/*******************************************************************
Event Modal
 ******************************************************************/

.event-modal-list {
    height: 100%;

    .modal-event {
        display: flex;

        .left, .right {
            &.first {
                border-top: 0;

                .name {
                    margin-top: 0;
                }
            }
        }

        .left {
            width: calc(692px * 0.7);
            padding: 0 30px 30px 0;
            border-right: 1px solid $black;
            border-top: 1px solid $black;
        }

        .right {
            width: calc(450px * 0.7);
            padding: 0 0 30px 30px;
            border-top: 1px solid $black;
        }

        .location-link {
            text-decoration: none;
            display: flex;
            align-items: center;

            .event-arrow {
                margin-left: 15px;
            }
        }
    }

    .date {
        margin-top: -45px;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
    }

    .name {
        margin-bottom: 5px;
        margin-top: 20px;
    }

    .time {
        margin-bottom: 20px;
    }

    .events-list {
        overflow-x: scroll;
        height: calc(100% - 75px);
    }

    .admission {
        margin-top: 20px;

        p {
            margin: 10px 0 25px;
        }
    }

    .location {
        .event-arrow {
            height: 35px;
            width: 35px;
        }

        .location-link {
            margin: 5px 0 40px;
        }
    }

    .share {
        #s-m-icons {
            float: left;
            margin-top: 10px;

            .twitter {
                margin-left: 0;
            }

            .email {
                margin-left: 0;
                margin-right: 16px;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .title {
            font-size: 33px;
        }

        .modal-event {
            flex-direction: column;

            .left {
                width: 100%;
                border-right: 0;
                padding-bottom: 7px;
                padding-right: 0;
            }

            .right {
                width: 100%;
                border-top: 0;
                padding: 0;
            }

            .location-link {
                margin-bottom: 20px;
            }

            .share {
                margin-bottom: 60px;
            }
        }

        .events-list {
            height: calc(100% - 135px);

            @media screen and (orientation: landscape) {
                height: calc(100% - 60px);
            }
        }
    }
}


/*******************************************************************
Event Carousel
 ******************************************************************/

.event-carousel-wrap {
    margin-top: 30px;
    margin-bottom: 60px;

    .slider {
        height: 320px !important;
    }

    .carousel-title {
        margin-left: 115px;
    }

    .event-carousel {
        height: 320px;
        margin-bottom: 30px;

        .event-info {
            height: 320px;
            padding: 10px 20px 0;

            .date {
                margin-bottom: 0;
            }

            .time {
                margin-top: 0;
            }

            .event-title {
                margin-top: 40px;
            }
        }
    }

    .slider-frame {
        margin: 0 95px !important;
    }

    .slider-slide {
        padding: 20px;
        border-right: 2px solid $black;
        min-height: 320px;
        cursor: pointer;
    }

    .slider-list {
        min-height: 320px;
    }

    @media screen and (max-width: 750px) {
        .slider-slide {
            border-right: 0;
        }

        .slide-visible:nth-of-type(1) {
            border-right: 0;
        }

        .carousel-title {
            margin-left: 0;
        }

        .slider-frame {
            width: 300px;
            margin: 0 auto !important;
        }

        .slider-control-centerleft {
            left: -20px !important;
        }

        .slider-control-centerright {
            right: -20px !important;
        }
    }


    @media screen and (max-width: 1020px) and (min-width: 750px) {
        .slide-visible:nth-of-type(2) {
            border-right: 0;
        }
    }

    @media screen and (max-width: 1220px) and (min-width: 1020px) {
        .slide-visible:nth-of-type(3) {
            border-right: 0;
        }
    }

    @media screen and (min-width: 1220px) and (max-width: 1490px) {
        .slide-visible:nth-of-type(4) {
            border-right: 0;
        }
    }

    @media screen and (min-width: 1799px) and (max-width: 2000px) {
        .slide-visible:nth-of-type(5) {
            border-right: 0;
        }
    }

    @media screen and (min-width: 2599px) {
        .slide-visible:nth-of-type(6) {
            border-right: 0;
        }
    }

    .carousel-control {
        &.disabled {
            background-image: url('/static/images/Arrow Button LG Disabled.svg');
            cursor: default;
        }
    }
}

.slider-control-bottomcenter {
    display: none;
}

.event-info {
    height: 200px;

    .date {
        margin-bottom: 0;
        margin-top: 0;
    }

    .time {
        margin-top: 0;
    }

    .event-title {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 500;
    }

    .description {
        max-width: 275px;
    }
}


/*******************************************************************
 Calendar picker
 ******************************************************************/

.options {
    .DateRangePicker {
        @media screen and (max-width: 800px) {
            position: absolute;
            left: 0;
        }


        .DateRangePickerInput {
            display: none !important;
        }

        .DateRangePicker_picker {
            top: 22px !important;
            left: -400px !important;
            font-family: 'Graphik';
            margin-left: 0 !important;

            @media screen and (max-width: 800px) {
                left: 0 !important;
            }

            .DayPicker_weekHeader {
                margin-left: 3px !important;
                padding: 0 !important;
            }
        }
    }

    .DayPicker {
        border: 2px solid black !important;
        border-radius: 0 !important;
        width: 383px !important;
    }

    .DayPicker__withBorder {
        -webkit-box-shadow: none;
                box-shadow: none;
    }

    .DayPicker_focusRegion, .DayPicker_transitionContainer {
        width: 382px !important;
    }

    .CalendarMonthGrid_month__horizontal {
        width: 365px !important;
    }

    .CalendarMonth_table {
        width: 345px;
    }

    .DayPickerKeyboardShortcuts_buttonReset {
        display: none !important;
    }

    .CalendarDay__default {
        vertical-align: middle;
        border: 0 !important;
    }

    .DayPicker_weekHeader_ul {
        padding-bottom: 5px;
        border-bottom: 1px solid $black;
        width: 359px !important;

    }

    .CalendarDay__selected {
        color: $black !important;
        background: $white !important;

        &.CalendarDay__selected_start {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }

        &.CalendarDay__selected_end {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
        }
    }

    .CalendarMonth_caption {
        padding-top: 17px !important;
        padding-bottom: 42px !important;
        text-transform: uppercase;
        color: $black !important;
        font-size: 13px !important;
    }

    .DayPicker_weekHeader_li {
        color: $black;
        margin-right: 10px !important;

        small {
            display: none !important;
        }

        &:nth-of-type(1)::after, &:nth-of-type(7)::after {
            content: 'S' !important;
        }

        &:nth-of-type(2)::after {
            content: 'M' !important;
        }

        &:nth-of-type(3)::after, &:nth-of-type(5)::after {
            content: 'T' !important;
        }

        &:nth-of-type(4)::after {
            content: 'W' !important;
        }

        &:nth-of-type(6)::after {
            content: 'F' !important;
        }

        &:nth-of-type(1) {
            width: 31px !important;
            margin-right: 14px !important;
            margin-left: 15px !important;
        }

        &:nth-of-type(6) {
            margin-right: 12px !important;
        }


    }

    .CalendarDay__default {
        color: $black !important;
    }

    .CalendarDay {
        font-weight: 300;
    }

    .CalendarDay__selected_span {
        background: $green !important;
    }

    .CalendarDay__hovered_span {
        background: rgba($green, .7) !important;
    }

    .CalendarDay__default:hover {
        &.CalendarDay__blocked_out_of_range {
            background: $white !important;
        }

        background: rgba($green, .3) !important;
    }

    .DayPickerNavigation_button {
        border: 0 !important;
    }

    .CalendarMonth {
        padding: 0px 10px !important;
    }

    .DayPickerNavigation_svg__horizontal {
        path {
            fill: $black;
        }
    }

    .CalendarDay__blocked_out_of_range {
        color: rgba($black, .2) !important;
    }

    .DayPickerNavigation_leftButton__horizontalDefault {
        top: 7px !important;
        left: 5px !important;
    }

    .DayPickerNavigation_rightButton__horizontalDefault {
        top: 7px !important;
        right: 5px !important;
    }

    .DayPicker_weekHeader {
        top: 47px !important;
    }

    .CalendarDay__selected_start, .CalendarDay__selected_end {
        .circle-container {
            background: $green;

            &:hover {
                background: rgba($green, .3);
            }
        }

        .date-circle {
            width: 33px;
            height: 33px;
            background: white;
            vertical-align: middle;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }

    .CalendarDay__selected_start {
        .circle-container {
            height: 100%;
            display: flex;
            align-items: center;
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            margin-left: 8px !important;
        }

        .date-circle {
            margin-left: 2px !important;
        }

        &:hover {
            background: $white !important;
        }
    }

    .CalendarDay__selected_end {
        .circle-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            align-items: center;
        }

        .date-circle {
            margin-right: 3px !important;
        }

        &:hover {
            background: $white !important;
        }
    }

    .CalendarDay__selected_end  {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 5px !important;
        width: auto !important
    }

}
