#downtown {
    padding-top: 90px;

    .downtown-title {
        margin-bottom: 10px;
    }

    a {
        color: $black;
        text-decoration: none;
    }

    .intro {
        margin-left: 30px;
        margin-bottom: 50px;
    }

    .outro {
        border-top: 2px solid $black;
        padding-left: 30px;
        margin-top: 20px;
        padding-top: 30px;
        margin-bottom: 80px;
    }

    .outro {
        .cke-block {
            img {
                max-height: 75px !important;
                max-width: 150px !important;
            }
        }
    }

    .player, .react-player__preview {
        margin-bottom: 40px;
    }

    .image-carousel {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 800px) {
        .content-block, .outro {
            padding-left: 0;
        }

        .intro {
            margin-left: 0;
        }

        .image-carousel {
            min-height: 350px;

            .image-item div, .slider-frame, .slider-slide {
                min-height: 350px;
                height: 350px;
            }
        }
    }
}
