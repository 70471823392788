#news {
    .calendar-buttons {
        display: flex;
        justify-content: center;

        .load-more {
            margin-top: 25px;
            margin-bottom: 32px;
        }
    }
}

.article-card {
    &.first-card {
        display: flex;
        justify-content: space-between;
        width: calc(75% - 50px);
        max-width: calc(75% - 50px);

        .card-info {
            width: calc(33% - 30px);
        }

        .card-image {
            height: 100%;
            width: calc(69% - 28px);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .date {
        border-bottom: 1px solid $black;
        padding-bottom: 15px;
    }

    .article-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - calc(110px * 0.7));

        .title {
            padding-top: 10px;
        }

        .author {
            text-transform: uppercase;
        }

        .extra-events {
            margin-top: 30px;
            margin-bottom: -47px;
        }
    }

    .card-info {
        height: 100%;
    }

    .read-article {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $black;
        padding-top: 12px;
        margin-top: 8px;

        .event-arrow {
            height: 30px;
            width: 30px;
        }
    }
}

.article-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.modal {
    height: 100%;
}

.article-modal {
    height: 100%;
    margin-top: -45px;
    overflow-x: scroll;

    .article-title {
        padding-bottom: 20px;
        margin-bottom: 15px;
        border-bottom: 1px solid $black;

        .title {
            margin: 0 0 15px 0;
        }

        .author-share {
            display: flex;
            justify-content: space-between;

            .share {
                display: flex;
                align-items: center;

                .instagram {
                    height: 15px;
                    width: 16px;
                    margin-left: 6px;
                    cursor: pointer;
                }

                .facebook {
                    height: 14px;
                    margin-left: 3px;
                    cursor: pointer;
                }

                .email {
                    height: 12px;
                    cursor: pointer;
                }

                .twitter {
                    height: 14px;
                    margin-left: 6px;
                    cursor: pointer;
                }
            }
        }

    }

    .article-content {
        .article-modal-image {
            height: 500px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            margin-bottom: 20px;
        }

        .article {
            p {
                margin: 15px 0;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .article-content {
            .article-modal-image {
                height: 300px;
            }
        }
    }
}
