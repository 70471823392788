@font-face {
    font-family: 'Graphik';
    src: url('/static/css/fonts/Graphik-Bold-Web.woff2') format('woff2'),
    url('/static/css/fonts/Graphik-Bold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('/static/css/fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('/static/css/fonts/Graphik-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('/static/css/fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('/static/css/fonts/Graphik-Regular-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Produkt';
    src: url('/static/css/fonts/Produkt-Light-Web.woff2') format('woff2'),
    url('/static/css/fonts/Produkt-Light-Web.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Produkt';
    src: url('/static/css/fonts/Produkt-Extralight-Web.woff2') format('woff2'),
    url('/static/css/fonts/Produkt-Extralight-Web.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
}
