#visit {
    .map {
        margin-bottom: 60px;
        width: 100%;
        background: $white;
        display: flex;

        .close-map {
            position: absolute;
            top: 60px;
            font-size: 50px;
            cursor: pointer;
            padding-left: 7px;
        }

        .location-maps {
            width: 55%;

            .map-image {
                width: 100%;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                height: 0;
                padding: 0;
                margin: 0;
            }

            .map-image, .flat-maps {
                cursor: pointer;

                &.map-key {
                    cursor: default;
                }
            }

            .map-icon {
                position: relative;
                width: 100%;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                height: 0;
                padding: 0;
            }
        }

        .location-list-menu {
            margin-bottom: 10px;

            .filter-options {
                li {
                    list-style: none;
                }
            }

            .menu-arrow {
                display: inline-block;
                background: url(/static/images/menu_arrow.svg);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                height: 20px;
                width: 20px;
                margin-left: 10px;
                -webkit-transition: -webkit-transform linear 0.1s;
                transition: -webkit-transform linear 0.1s;
                -o-transition: transform linear 0.1s;
                transition: transform linear 0.1s;
                transition: transform linear 0.1s, -webkit-transform linear 0.1s;
                cursor: pointer;

                &.menu-arrow-right {
                    -webkit-transition: -webkit-transform linear 0.1s;
                    transition: -webkit-transform linear 0.1s;
                    -o-transition: transform linear 0.1s;
                    transition: transform linear 0.1s;
                    transition: transform linear 0.1s, -webkit-transform linear 0.1s;
                    -webkit-transform: rotate(-90deg);
                        -ms-transform: rotate(-90deg);
                            transform: rotate(-90deg);
                }
            }

            .title {
                cursor: pointer;
            }
        }

        .location-list {
            width: 45%;

            .locations {
                max-height: 1000px;
            }

            .location {
                width: 50%;
            }
        }
    }

    .skewed-maps, .places-level-1-map, .places-level-2-map, .places-level-3-map {
        transform: scaleY(1);
        transition: transform 0.2s;

        &.hide-map {
            transform: scaleY(0);
            transition: transform 0.2s;
        }

        &.collapsed {
            display: none;
        }
    }

    .content-block {
        p {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 1000px) {
        .map {
            flex-direction: column;

            .location-list-menu {
                padding: 20px 20px 10px;
            }

            .location-maps {
                width: 100%;
            }

            .location-list {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .map {
            .location-list {

                .locations {
                    flex-wrap: nowrap;
                    max-height: unset;
                }

                .location {
                    width: 100%;
                }
            }
        }

        .map-key {
            display: none;
        }
    }
}
