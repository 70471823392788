#newsletter {
    height: 100%;

    .modal-content {
        height: 100%;
        margin-top: -80px;

        .intro {
            margin-bottom: 40px;
        }

        form {
            height: 100%;
            overflow-x: scroll;
        }
    }
}
