$green: rgb(70, 255, 135);
$yellow: rgb(255, 249, 90);
$magenta: rgb(255, 142, 236);
$cyan: rgb(94, 255, 222);
$violet: rgb(107, 143, 237);

$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$red: rgb(255, 0, 0);

// Export for use in React components
:export {
    green: $green;
    yellow: $yellow;
    magenta: $magenta;
    cyan: $cyan;
    violet: $violet;

    black: $black;
    white: $white;
    red: $red;
}
