#about {
    padding-top: 90px;

    .blocks {
        p {
            margin: 20px 0 0;
        }

        .content-block {
            margin-bottom: 40px;
        }
    }

    .image-carousel {
        margin-bottom: 50px;
        min-height: calc(781px * 0.7);

        .slider-frame, .slider-slide {
            min-height: calc(781px * 0.7);
        }

        .carousel-control {
            &.left-arrow {
                margin-left: 30px;
            }

            &.right-arrow {
                margin-right: 30px;
            }
        }
    }

    .image-carousel .image-item div {
        height: 550px;
        background-position-y: 70%;
    }

    @media screen and (max-width: 800px) {
        .image-carousel {
            .carousel-control {
                &.left-arrow {
                    margin-left: 10px;
                }

                &.right-arrow {
                    margin-right: 10px;
                }
            }
        }

        .image-carousel {
            min-height: 350px;

            .image-item div, .slider-frame, .slider-slide {
                min-height: 350px;
                height: 350px;
            }
        }
    }
}
