#magazine {
    .magazine-list {
        display: flex;

        a {
            text-decoration: none;
        }

        .current-magazine {
            width: 50%;

            .magazine {
                display: flex;
                align-items: flex-start;
                margin-top: 12px;

                .magazine-info {
                    margin-left: 25px;

                    .button {
                        margin-top: 120px;
                    }
                }
            }

            .magazine-image {
                margin-top: 8px;
                height: 410px;
                width: 316px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        .old-magazines {
            width: 50%;
            padding-left: 40px;
            margin-bottom: 80px;

            .info {
                margin-bottom: 20px;
            }

            .old-magazines-list {
                display: flex;
                flex-wrap: wrap;

                .old-magazine {
                    width: 180px;
                    margin-right: 30px;

                    .magazine-title {
                        margin: 5px 0 20px;
                    }
                }

                .magazine-image {
                    height: 230px;
                    width: 180px;
                }
            }

            .magazine-image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 50%;
                display: inline-block;
            }
        }

    }

    .form-message, .form-error {
        color: $black !important;
    }

    @media screen and (max-width: 800px) {
        .magazine-list {
            .current-magazine {
                .magazine {
                    flex-direction: column;

                    .magazine-info {
                        margin-left: 0;
                        margin-top: 15px;

                        .button {
                            margin-top: 30px;
                        }
                    }
                }

                .magazine-image {
                    width: 100%;
                }
            }

            .old-magazines-list {
                justify-content: center;
            }
        }

        @media screen and (orientation: landscape) {
            .magazine-list {
                .current-magazine {
                    .magazine {
                        flex-direction: row;

                        .magazine-info {
                            margin-left: 40px;
                        }
                    }

                    .magazine-image {
                        width: 50%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .magazine-list {
            flex-direction: column;

            .current-magazine {
                width: 100%;
            }

            .old-magazines {
                width: 100%;
                padding-left: 0;
                margin-top: 50px;
            }
        }
    }
}
