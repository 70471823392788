#leasing {
    padding-top: 90px;

    .leasing-title {
        margin-bottom: 10px;
    }

    .cke-title {
        width: 85%;
    }

    a {
        color: $black;
        text-decoration: none;
    }

    .intro {
        margin-left: 30px;
        margin-bottom: 50px;
    }

    .main-content {
        .cke-button {
            margin-top: 15px;
        }
    }

    .image-carousel {
        margin-bottom: 50px;
        min-height: calc(781px * 0.7);

        .slider-frame, .slider-slide {
            min-height: unset;
            min-height: calc(781px * 0.7);
        }

        .carousel-control {
            &.left-arrow {
                margin-left: 30px;
            }

            &.right-arrow {
                margin-right: 30px;
            }
        }
    }

    .full-block {
        .main-content {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .content-block {
            img {
                max-height: 75px !important;
                max-width: 150px !important;
                filter: grayscale(100%);
            }
        }
    }

    .image-carousel .image-item div {
        height: 550px;
        background-position-y: 70%;
    }

    @media screen and (max-width: 800px) {
        .intro {
            margin-left: 0;

            .cke-title {
                font-size: 31px;
            }
        }

        .content-block {
            padding-left: 0;
        }

        .full-block {
            .center-images {
                .cke-block {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
